import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants/api";
import { IMatchesPerTeams, ITeam, TeamClass } from "../types";

const QUERY_KEY = "matches_per_team";

export function useMatchesPerTeam(
  teamId: string,
  options?: UseQueryOptions<IMatchesPerTeams[], AxiosError>
) {
  return useQuery(
    [QUERY_KEY, teamId],
    async () => {
      const { data: data } = await client.get(
        ENDPOINTS.MATCHES_PER_TEAM.replace("{teamId}", teamId)
      );
      return [...data.matches];
    },
    // @ts-ignore
    options
  );
}
