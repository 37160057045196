import { Tab } from "@headlessui/react";

export function KnockoutFaseSelector({
	data,
	selectedKnockoutFase,
	setSelectedKnockoutFase,
}: {
	data: any;
	selectedKnockoutFase: number;
	setSelectedKnockoutFase: (index: number) => void;
}) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				overflowY: "scroll",
				flexWrap: "nowrap",
				outline: "none",
			}}
			tabIndex={0}
		>
			<Tab.Group
				onChange={(d) => {
					if (d === selectedKnockoutFase) {
						return;
					} else {
						setSelectedKnockoutFase(d);
					}
				}}
				selectedIndex={selectedKnockoutFase}
				defaultIndex={selectedKnockoutFase}
			>
				<Tab.List
					style={{
						overflowY: "scroll",
						display: "flex",
						overflowX: "scroll",
						flexWrap: "nowrap",
						width: "100%",
						scrollbarColor: "#000",
						scrollbarWidth: "none",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							overflowY: "scroll",
							outline: "none",
						}}
					>
						{data?.map((knockout: any, key: number) => {
							const isSelected = selectedKnockoutFase === key;
							return (
								<Tab
									key={key}
									style={{
										outline: "none",
										minWidth: 110,
										padding: "0.5rem",
										borderBottom: isSelected ? "2px solid #653EF2" : "1px solid #DEE2E6",
									}}
									className="sm:w-[260px]"
								>
									<p
										style={{
											display: "block",
											fontWeight: isSelected ? 600 : 500,
											cursor: "pointer",
										}}
									>
										{knockout.name}
									</p>
								</Tab>
							);
						})}
					</div>
				</Tab.List>
			</Tab.Group>
		</div>
	);
}
