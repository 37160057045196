import moment from "moment";
import "moment/locale/nl";

export const formatDate = (data: string) => {
  const d = moment.parseZone(data);
  d.locale("nl");
  return d.format("dddd D MMMM YYYY");
};

export const getTime = (data: string) => {
  const d = moment.parseZone(data);
  d.locale("nl");
  return d.format("HH:mm");
};

export const getDay = (data: string) => {
  const d = moment.parseZone(data);
  d.locale("nl");
  return d.format("dddd");
};

export const sameDates = ({
  date1,
  date2,
}: {
  date1: Date | undefined;
  date2: Date | undefined;
}) => {
  return moment(date1).isSame(date2, "day");
};
