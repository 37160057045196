import { useLocation, useNavigate } from "react-router-dom";
import { PageLayout } from "../../components/pageLayout";
import SelectFromList from "../../components/selectFromList";
import Title from "../../components/title";
import { useTournaments } from "../../data/api/useTournament/useTournament";
import { useTournamentOptions } from "../../store";

export function SelectTournament() {
  // GET TOURNAMENT ID FROM STATE
  const selectedTournament = useTournamentOptions(
    (state) => state.tournamentId
  );

  // CHANGE TOURNAMENT STATE
  const changeTournamentId = useTournamentOptions(
    (state) => state.setTournamentId
  );
  const setTournamentName = useTournamentOptions(
    (store) => store.setTournamentName
  );
  const changeTeamId = useTournamentOptions((state) => state.setTeamId);

  // FETCHES TOURNAMENTS
  const { data: tournaments, isLoading } = useTournaments();

  const location = useLocation();
  const navigate = useNavigate();

  const onSelectTournament = ({ id, name }: { id: string; name: string }) => {
    changeTournamentId(id);
    setTournamentName(name);
    if (location.pathname.includes("change")) {
      navigate("/");
      changeTeamId("");
    }
  };

  return (
    <PageLayout
      header={<Title text={"Kies jouw toernooi uit deze lijst"} />}
      main={
        <SelectFromList
          values={tournaments?.tournaments || []}
          onChange={onSelectTournament}
          selectedValue={selectedTournament || tournaments?.tournaments[0].id}
        />
      }
    />
  );
}
