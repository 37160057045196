import { useEffect, useState } from "react";
import Title from "../../components/title";
import { IMatch, IMatchesPerTeams } from "../../data/api/types";
import { useMatchesPerTeam } from "../../data/api/useMatches/useMatchesPerTeam";
import { BottomNavBar } from "../../routers/BottomNavBar";
import { useTournamentOptions } from "../../store";
import { formatDate, sameDates } from "../utils/formatDate";
import { SelectDay } from "./SelectDay";
import { sortDates, sortMatches } from "./sortDates";
import { WedstrijdTable } from "./wedstrijdTable";

export function Wedstrijden() {
	return <BottomNavBar screen={<WedstrijdenScreen />} />;
}

export function findIndexOfSortedMatches({
	matches,
	date,
}: {
	matches: IMatchesPerTeams[];
	date: string;
}) {
	const sortedMatches = sortDates(matches);
	const index = sortedMatches.findIndex((match) =>
		sameDates({ date1: new Date(date), date2: new Date(match.date) })
	);
	return index;
}

export function WedstrijdenScreen() {
	const teamId = useTournamentOptions((store) => store.teamId);

	const { data, isLoading } = useMatchesPerTeam(teamId || "");
	const [sortedMatches, setSortedMatches] = useState<IMatchesPerTeams[]>();
	const [selectedDate, setSelectedDate] = useState<Date>();
	const [selectedIndex, setSelectedIndex] = useState<number>(0);

	useEffect(() => {
		if (data?.length) {
			const sortedMatches = sortDates(data);
			setSortedMatches(sortedMatches);
			setSelectedDate(new Date(sortedMatches?.[0]?.date));
			console.log(new Date(sortedMatches?.[0]?.date));
		}
	}, [data]);

	return (
		<div>
			<div className="sticky top-0 bg-white">
				<div className="border-b border-gray-300  bg-white">
					<div className="p-5 font-semibold">
						<Title text="Onze wedstrijden" />
					</div>
				</div>
				<SelectDay
					selectedValue={selectedDate}
					values={sortedMatches || []}
					onChange={(date: Date) => {
						if (sortedMatches) {
							const index = findIndexOfSortedMatches({
								matches: sortedMatches,
								date: date.toISOString(),
							});
							setSelectedIndex(index);
						}

						setSelectedDate(date);
					}}
				/>
			</div>
			<div style={{ overflowY: "scroll", paddingBottom: 70 }}>
				{!sortedMatches?.[selectedIndex]?.pouleGames.length ? null : (
					<h1 style={{ marginTop: 12 }} className="text-xl px-5 pb-5  ">
						Poulefase
					</h1>
				)}{" "}
				{sortedMatches?.length &&
					sortMatches(sortedMatches?.[selectedIndex].pouleGames).map((match: IMatch) => {
						return (
							<div
								style={{
									marginBottom: 20,
									borderBottom: "1px solid #DEE2E6",
								}}
							>
								<WedstrijdTable data={[match]} />
							</div>
						);
					})}
				{!sortedMatches?.[selectedIndex]?.knockoutGames.length ? null : (
					<h1 className="text-xl px-5 pb-5 ">Knockouts</h1>
				)}
				{sortedMatches?.length &&
					sortMatches(sortedMatches?.[selectedIndex].knockoutGames).map(
						(match: IMatch) => {
							return (
								<div
									style={{
										marginBottom: 20,
										borderBottom: "1px solid #DEE2E6",
									}}
								>
									<WedstrijdTable data={[match]} />
								</div>
							);
						}
					)}
			</div>
		</div>
	);
}
