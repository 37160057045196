import React from "react";
import { SelectTeam } from "./pages/select-team";
import { SelectTournament } from "./pages/select-tournament";
import { MainRoutes } from "./routers/mainRoutes";
import { useTournamentOptions } from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
import WindowFocusHandler from "./pages/utils/WIndowsFocusHandles";

function App() {
	const teamId = useTournamentOptions((state) => state.teamId);
	const tournamentId = useTournamentOptions((state) => state.tournamentId);

	if (tournamentId && teamId) {
		return <MainRoutes />;
	} else if (!tournamentId) {
		return <SelectTournament />;
	} else if (!teamId) {
		return <SelectTeam />;
	} else {
		return <span>Er is iets fout gegaan</span>;
	}
}

export default App;

const queryClient = new QueryClient();

export function AppWrapper() {
	return (
		<QueryClientProvider client={queryClient}>
			<WindowFocusHandler />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					margin: "0 auto",
					height: "100vh",
					backgroundColor: "#e5e5e5",
				}}
			>
				<div
					style={{
						backgroundColor: "#fff",
						// maxHeight: "926px",
						height: "100%",
						width: "100%",
						// maxWidth: "480px",
					}}
				>
					<App />
				</div>
			</div>
		</QueryClientProvider>
	);
}
