import { BottomNavBar } from "../../routers/BottomNavBar";
import { Disclosure } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useTournamentOptions } from "../../store";
import { Arrow } from "../../components/Arrow";

export const vragenEnAntwoorden = [
  {
    question: "Hoeveel teams kunnen er maximaal mee doen met een toernooi?",
    answer: "hello",
  },
  {
    question: "Hoeveel teams kunnen meedoen?",
    answer: "hello",
  },
  {
    question: "Hoeveel teams kunnen meedoen?",
    answer: "hello",
  },
  {
    question: "Hoeveel teams kunnen meedoen?",
    answer: "hello",
  },
];

export function Overige() {
  return <BottomNavBar screen={<OverigeScreen />} />;
}

export function OverigeScreen() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#F1F3F5",
        overflow: "scroll",
        paddingBottom: 100,
      }}
    >
      <div
        style={{
          padding: "20px 20px",
          background: "#fff",
        }}
      >
        <h1 style={{ fontWeight: 600, fontSize: 20, color: "#3C3C3C" }}>
          Versie 1.0
        </h1>
        <p style={{ color: "#837D7D" }}>© Copyright Tournamate</p>
      </div>
      <TournamentSettings />
      <Suggesties />
      {/* <Veelgesteldevragen questionsAndAswers={vragenEnAntwoorden} /> */}
    </div>
  );
}

function Veelgesteldevragen({
  questionsAndAswers,
}: {
  questionsAndAswers: { question: string; answer: string }[];
}) {
  return (
    <>
      <div
        className="divide-y "
        style={{ backgroundColor: "#fff", padding: "0px 0px", marginTop: 20 }}
      >
        <h1
          style={{
            padding: "15px 0px 0px 20px",
            fontWeight: 600,
            fontSize: 20,
            marginBottom: 15,
          }}
        >
          Veelgestelde vragen
        </h1>

        {questionsAndAswers.map((data, key) => {
          return (
            <div style={{ padding: "15px 20px" }}>
              <Disclosure>
                <Disclosure.Button
                  style={{
                    textAlign: "left",
                    fontWeight: 600,
                    display: "flex",
                    color: "#3C3C3C",
                  }}
                >
                  {data.question}
                </Disclosure.Button>
                <Disclosure.Panel style={{ color: "#3C3C3C" }}>
                  {" "}
                  {data.answer}
                </Disclosure.Panel>
              </Disclosure>
            </div>
          );
        })}
      </div>
    </>
  );
}

export function Suggesties() {
  return (
    <div
      //   className="divide-y "
      style={{ backgroundColor: "#fff", padding: "0px 0px", marginTop: 20 }}
    >
      <h1
        style={{
          padding: "15px 0px 0px 20px",
          fontWeight: 600,
          fontSize: 20,
        }}
      >
        Vragen of suggesties?
      </h1>
      <span style={{ marginBottom: 15, marginLeft: 20 }}>
        Laat het ons even weten!
      </span>
      <div
        style={{
          width: "100vw",
          height: 1,
          backgroundColor: "#DEE2E6",
          marginTop: 12,
          marginBottom: 0,
        }}
      />
      <div className="divide-y" style={{ paddingBottom: 10 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 20px",
          }}
        >
          <span>Stuur een mail</span>
          <div
            className="space-x-4"
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              window.open("mailto:info@tournamate.nl");
            }}
          >
            <span style={{ color: "#837D7D" }}>info@tournamate.nl</span>
            <Arrow />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 20px",
          }}
        >
          <span>Bel ons even</span>
          <div
            className="space-x-4"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              window.open("tel:+31612897643");
            }}
          >
            <span style={{ color: "#837D7D", cursor: "pointer" }}>
              06 29 16 42 59
            </span>
            <Arrow />
          </div>
        </div>
      </div>
    </div>
  );
}

export function TournamentSettings() {
  const navigate = useNavigate();
  const teamName = useTournamentOptions((store) => store.teamName);
  const tournamentName = useTournamentOptions((store) => store.tournamentName);

  return (
    <div
      //   className="divide-y "
      style={{
        backgroundColor: "#fff",
        padding: "0px 0px",
        marginTop: 20,
      }}
    >
      <h1
        style={{
          padding: "15px 0px 0px 20px",
          fontWeight: 600,
          fontSize: 20,
          marginBottom: 15,
        }}
      >
        Wijzig je team of toernooi
      </h1>
      <div
        style={{
          width: "100vw",
          height: 1,
          backgroundColor: "#DEE2E6",
          marginTop: 12,
          marginBottom: 0,
        }}
      />
      <div className="divide-y" style={{ paddingBottom: 10 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 20px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/change-tournament");
          }}
        >
          <span style={{ fontWeight: 600 }}>Wijzig toernooi</span>
          <div
            className="space-x-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ color: "#837D7D" }}>{tournamentName || ""}</span>
            <Arrow />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 20px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/change-team");
          }}
        >
          <span style={{ fontWeight: 600 }}>Wijzig team</span>
          <div
            className="space-x-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ color: "#837D7D" }}>{teamName || ""}</span>
            <Arrow />
          </div>
        </div>
      </div>
    </div>
  );
}
