import { RadioGroup } from "@headlessui/react";

export default function SelectFromList({
  values,
  onChange,
  selectedValue,
}: {
  values: { id: string; name: string }[] | [] | undefined;
  onChange: (value: any) => void;
  selectedValue: string;
}) {
  return (
    <RadioGroup value={selectedValue} onChange={onChange}>
      {values?.map((values, key) => {
        return (
          <RadioGroup.Option key={key} value={values}>
            {({ checked }) => (
              <div
                key={values.id}
                className={`p-5 ${
                  checked ? "bg-white" : "bg-neutral-100"
                } flex items-center  border-neutral-400 border-b-[1px]`}
              >
                <span className="ml-4">{values.name}</span>
              </div>
            )}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
}
