import { IMatch, IMatchesPerTeams } from "../../data/api/types";

export function sortDates(matches: IMatchesPerTeams[]) {
  return matches.sort((a, b) => {
    return new Date(a.date).getDate() - new Date(b.date).getDate();
  });
}

export function sortMatches(matches: IMatch[]) {
  return matches.sort((a, b) => {
    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  });
}
