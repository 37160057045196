import { Switch } from "@headlessui/react";
import useWindowDimensions from "../utils/windowSizeHook";

export function ShowDetailsSwitch({
	detailed,
	setIsDetailed,
}: {
	detailed: boolean;
	setIsDetailed: (value: boolean) => void;
}) {
	const { width, height } = useWindowDimensions();

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: width < 500 ? "space-between" : "unset",
				padding: "20px 20px",
			}}
		>
			Toon details
			<Switch
				checked={detailed}
				style={{ marginLeft: 15 }}
				onChange={setIsDetailed}
				className={`${
					detailed ? "bg-[#3ECA7E]" : "bg-gray-200"
				} relative inline-flex h-7 w-11 items-center rounded-full`}
			>
				<span className="sr-only">Enable notifications</span>
				<span
					className={`${
						detailed ? "translate-x-6" : "translate-x-1"
					} inline-block h-4 w-4 transform rounded-full bg-white`}
				/>
			</Switch>
		</div>
	);
}
