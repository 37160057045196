export function SelectStandFase({
  onChange,
  value,
}: {
  onChange: (value: any) => void;
  value: string;
}) {
  const baseStyling = "w-1/2 rounded-sm text-center p-3 uppercase";
  const selectedStyling = "bg-purple-primary text-white ";
  const notSelectedStyling = "bg-slate-100";

  return (
    <div className="flex w-full p-5 justify-between">
      <div
        style={{ cursor: "pointer", borderRadius: "6px 0px 0px 6px" }}
        onClick={() => onChange("Poules")}
        className={`${
          value === "Poules" ? selectedStyling : notSelectedStyling
        }  ${baseStyling}`}
      >
        <span className={"font-semibold"}> Poules</span>
      </div>
      <div
        style={{ cursor: "pointer", borderRadius: "0px 6px 6px 0px" }}
        onClick={() => onChange("Knock-out")}
        className={`${
          value === "Knock-out" ? selectedStyling : notSelectedStyling
        }  ${baseStyling}`}
      >
        <span className={"font-semibold"}> Knock-out</span>
      </div>
    </div>
  );
}
