import { Fragment, memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../components/title";
import { BottomNavBar } from "../../routers/BottomNavBar";
import { usePoolsQuery } from "../../data/api/usePoules/usePoolsQuery";
import { useTournamentOptions } from "../../store";
import { SelectStandFase } from "./SelectStandFase";
import { IKnockout, IKnockoutGame, IPool } from "../../data/api/types";
import { PoolsTable } from "./PoolsTable";
import { PouleTabs } from "./PouleTabs";
import { useKnockOutsQuery } from "../../data/api/useKnockOuts";
import { Knockouts as KnockoutType } from "../../data/api/types";
import { KnockoutsTable } from "./KnockoutsTable";
import { ShowDetailsSwitch } from "./ShowDetailsSwitch";
import { KnockoutFaseSelector } from "./KnockoutFaseSelector";

export function Standen() {
	return <BottomNavBar screen={<StandenPage />} />;
}

function StandenPage() {
	const location = useLocation();

	const [standenOpties, setStandenOpties] = useState({
		isPoulesFase: true,
		isKnockOutFase: false,
		detailedView: false,
	});

	const tournamentId = useTournamentOptions((store) => store.tournamentId);
	const isHerenToernooi = useTournamentOptions((store) => store.isHerenToernooi);
	const teamId = useTournamentOptions((store) => store.teamId);

	const { data, isError, isLoading } = usePoolsQuery(tournamentId as string);

	const [selectedPouleIndex, setSelectedPouleIndex] = useState(0);
	const [detailed, setIsDetailed] = useState(false);

	const sortPouleNames = (data: IPool[]) => {
		return data?.sort((a, b) => a.name.localeCompare(b.name));
	};

	const [sortedPoules, setSortedPoules] = useState(
		sortPouleNames(isHerenToernooi ? data?.men : data?.women) || []
	);

	useEffect(() => {
		//
		if (location.pathname.includes("/app/standen/")) {
			setStandenOpties({
				...standenOpties,
				isPoulesFase: true,
				isKnockOutFase: false,
			});
		} else {
			setStandenOpties({
				...standenOpties,
				isPoulesFase: false,
				isKnockOutFase: true,
			});
		}
	}, [location]);

	useEffect(() => {
		// Wanneer de lijst van poules veranderd, wordt de lijst van poules opnieuw gesorteerd
		if (data) {
			const sorted = sortPouleNames(isHerenToernooi ? data?.men : data?.women);
			setSortedPoules(sorted);
			console.log(sorted);

			// Keeps track of index position of poule
			let i = 0;
			sorted?.map((value) => {
				// Increment i by one
				i++;
				const index = value.teams.findIndex((team) => team.team.id === teamId);
				if (index < 0) {
					return;
				} else {
					setSelectedPouleIndex(i - 1);
				}
			});
		}
	}, [data]);

	return (
		<div>
			<div className="sticky top-0 bg-white z-10">
				<div className="border-b border-gray-300">
					<div className="p-5 font-semibold">
						<Title text="Actuele stand" />
					</div>
				</div>
				<SelectStandFase
					value={standenOpties.isPoulesFase ? "Poules" : "Knock-out"}
					onChange={(value) => {
						if (value === "Poules") {
							setStandenOpties({
								...standenOpties,
								isPoulesFase: true,
								isKnockOutFase: false,
							});
						} else {
							setStandenOpties({
								...standenOpties,
								isPoulesFase: false,
								isKnockOutFase: true,
							});
						}
					}}
				/>
			</div>

			{standenOpties.isPoulesFase ? (
				<Poules
					setSelectedPouleIndex={setSelectedPouleIndex}
					sortedPoules={sortedPoules}
					selectedPouleIndex={selectedPouleIndex}
					isHerenToernooi={isHerenToernooi}
					detailed={detailed}
					setIsDetailed={setIsDetailed}
				/>
			) : (
				<Knockouts isHerenToernooi={isHerenToernooi} tournamentId={tournamentId} />
			)}
		</div>
	);
}

function Poules({
	setSelectedPouleIndex,
	sortedPoules,
	selectedPouleIndex,
	isHerenToernooi,
	detailed,
	setIsDetailed,
}: {
	setSelectedPouleIndex: (index: number) => void;
	sortedPoules: IPool[];
	selectedPouleIndex: number;
	isHerenToernooi: boolean;
	detailed: boolean;
	setIsDetailed: (value: boolean) => void;
}) {
	return (
		<>
			<PouleTabs
				setSelectedPouleIndex={setSelectedPouleIndex}
				selectedPouleIndex={selectedPouleIndex}
				data={sortedPoules}
				isHerenToernooi={isHerenToernooi}
			/>
			{!sortedPoules.length ? null : (
				<ShowDetailsSwitch detailed={detailed} setIsDetailed={setIsDetailed} />
			)}

			{!sortedPoules.length ? null : (
				<PoolsTable
					isDetailedMode={detailed}
					data={sortedPoules && sortedPoules[selectedPouleIndex]}
				/>
			)}
		</>
	);
}

export function Knockouts({
	isHerenToernooi,
	tournamentId,
}: {
	isHerenToernooi: boolean;
	tournamentId: string | null;
}) {
	const { data, error } = useKnockOutsQuery(tournamentId as string);
	const [selectedKnockoutFase, setSelectedKnockoutFase] = useState(0);
	const [knockouts, setKnockouts] = useState<IKnockout[]>();

	// Deze stored de standaard volgorde van de knockout fase
	const orderOfKnockoutFases = Object.values(KnockoutType);

	useEffect(() => {
		// @ts-ignore
		if (isHerenToernooi && data) {
			// Deze sorteert de data (van de mannen) op basis van de knockout volgorde
			const sorted = data?.men.sort(
				(a: IKnockout, b: IKnockout) =>
					// @ts-ignore
					orderOfKnockoutFases.indexOf(a.type) -
					// @ts-ignore
					orderOfKnockoutFases.indexOf(b.type)
			);
			setKnockouts(sorted);
		} else {
			// Deze sorteert de data (van de vrouwen) op basis van de knockout volgorde
			const sorted = data?.women.sort(
				(a: IKnockout, b: IKnockout) =>
					// @ts-ignore
					orderOfKnockoutFases.indexOf(a.type) -
					// @ts-ignore
					orderOfKnockoutFases.indexOf(b.type)
			);
			setKnockouts(sorted);
		}
	}, [data]);

	if (knockouts?.length) {
		return (
			<>
				<KnockoutFaseSelector
					data={knockouts ? knockouts : []}
					selectedKnockoutFase={selectedKnockoutFase}
					setSelectedKnockoutFase={setSelectedKnockoutFase}
				/>
				<KnockoutsTable data={knockouts?.[selectedKnockoutFase].games || []} />
			</>
		);
	} else {
		return null;
	}
}
