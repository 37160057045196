import { Routes, Route, Link, Navigate } from "react-router-dom";
import { Overige } from "../pages/overige";
import { SelectTeam } from "../pages/select-team";
import { SelectTournament } from "../pages/select-tournament";
import { Standen } from "../pages/standen";
import { Wedstrijden } from "../pages/wedstrijden";

export function MainRoutes() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/app/standen" replace />} />
      <Route path="/app/">
        <Route path="standen" element={<Standen />} />
        <Route path="wedstrijden" element={<Wedstrijden />} />
        <Route path="overige" element={<Overige />} />
      </Route>
      <Route path="/change-tournament" element={<SelectTournament />} />
      <Route path="/change-team" element={<SelectTeam />} />
    </Routes>
  );
}
