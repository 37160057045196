export function PageLayout({
  header,
  main,
  filter,
  sticky,
}: {
  header: JSX.Element;
  main: JSX.Element;
  filter?: JSX.Element;
  sticky?: boolean;
}) {
  return (
    <>
      <div
        style={{
          height: "100%",
          overflow: "scroll",
          position: sticky ? "sticky" : "unset",
          top: 0,
        }}
      >
        <div
          style={{ position: "sticky", top: 0 }}
          className="bg-neutral-100 flex  font-semibold items-start justify-between p-5 sm:items-center  small-shadow border-b-2"
        >
          {header}
        </div>
        <div style={{ position: "sticky", top: 0 }}>{filter}</div>
        {main}
      </div>
    </>
  );
}
