import React, { useEffect } from "react";

// User has switched away from the tab (AKA tab is hidden)
const onBlur = () => {
  console.log("Tab is blurred");
};

const WindowFocusHandler = () => {
  useEffect(() => {
    document.addEventListener("visibilitychange", function (event) {
      if (document.hidden) {
        return;
      } else {
        window.location.reload();
      }
    });

    return () => {
      document.removeEventListener("visibilitychange", () => {});
    };
  }, []);

  return <></>;
};

export default WindowFocusHandler;
