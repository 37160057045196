import create from "zustand";
import { persist } from "zustand/middleware";

export interface ITournamentOptions {
  tournamentId: string | null;
  teamId: string | null;
  isHerenToernooi: boolean;
  tournamentName: string | null;
  teamName: string | null;
}

export interface ITournamentActions extends ITournamentOptions {
  setTournamentId: (tournamentId: string | null) => void;
  setTeamId: (teamId: string | null) => void;
  setIsHerenToernooi: (isHerenToernooi: boolean) => void;
  setTeamName: (teamName: string | null) => void;
  setTournamentName: (tournamentName: string | null) => void;
}

export interface ITeam {
  id: string;
  tournamentId: string;
  name: string;
  email: string;
  phoneNumber: string;
  street: string;
  streetNumber: string;
  city: string;
  addition: string;
  postalCode: string;
}

export interface ITournament {
  id: string;
  name: string;
}

export const useTournamentOptions = create(
  persist<ITournamentActions>(
    (set, get) => ({
      tournamentId: null,
      teamId: null,
      isHerenToernooi: false,
      tournamentName: "",
      teamName: "",
      setTeamName: (teamName: string | null) =>
        set((state) => ({ ...state, teamName })),
      setTournamentName: (tournamentName: string | null) =>
        set((state) => ({ ...state, tournamentName })),
      setIsHerenToernooi: (isHerenToernooi: boolean) => {
        set((state) => ({ ...state, isHerenToernooi }));
      },
      setTournamentId: (tournamentId: string | null) =>
        set((state) => ({ ...state, tournamentId })),
      setTeamId: (teamId: string | null) =>
        set((state) => ({ ...state, teamId })),
    }),
    {
      name: "tournamentOptions",
    }
  )
);
