export enum ENDPOINTS {
  TOURNAMENT_SETTINGS = "/tournament/settings/{tournamentId}",
  TEAM_MEN = "/team/men/{tournamentId}",
  TEAM_WOMEN = "/team/women/{tournamentId}",

  POOL_MEN = "/poule/men/{tournamentId}",
  POOL_WOMEN = "/poule/women/{tournamentId}",

  KNOCKOUT_MEN = "/poule/knockout/men/{tournamentId}",
  KNOCKOUT_WOMEN = "/poule/knockout/women/{tournamentId}",

  MATCH_MEN = "/match/men/{tournamentId}",
  MATCH_WOMEN = "/match/women/{tournamentId}",

  ALL_TOURNAMENTS = "/tournament/all",

  MATCHES_PER_TEAM = "/match/team/{teamId}",
}

export enum KNOCKOUT {
  LAST_16 = "LAST_16",
  QUARTER_FINAL = "QUARTER_FINAL",
  HALF_FINAL = "HALF_FINAL",
  FINAL = "FINAL",
  LAST_32 = "LAST_32",
  POULE = "POULE",
  EXTRA_POULE_ROUND_WINNERS = "EXTRA_POULE_ROUND_WINNERS",
  EXTRA_POULE_ROUND_LOSERS = "EXTRA_POULE_ROUND_LOSERS",
}
