import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants/api";
import { ITeam, TeamClass } from "../types";
import { ITournament, ITournamentOptions } from "../../../store";

const QUERY_KEY = "tournament";

export function useTournaments() {
  return useQuery([QUERY_KEY], async () => {
    const { data } = await client.get(ENDPOINTS.ALL_TOURNAMENTS);
    return { tournaments: data };
  });
}
