export enum TeamClass {
  MEN = "men",
  WOMEN = "women",
}

export interface ITeam {
  addition: string;
  city: string;
  distanceKM: string;
  email: string;
  id: string;
  name: string;
  parseId: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  teamClass: TeamClass;
}

export interface IPoolTeam {
  matchesPlayed: number;
  matchesWon: number;
  matchesLost: number;
  matchesTied: number;
  goalCount: number;
  points: number;
  team: {
    id: string;
    name: string;
  };
}

export interface IPoolGame {
  endTime: string;
  fieldNumber: number;
  id: string;
  poule: string;
  referee: string;
  startTime: string;
  team1Score: null | number;
  team2Score: null | number;
  team1: string;
  team2: string;
}

export interface IPool {
  name: string;
  teams: IPoolTeam[];
  games: IPoolGame[];
}

export interface IMatchScore {
  id: string;
  tournamentId: string;
  team1Score: number | null;
  team2Score: number | null;
}

export interface IUpdatedFieldNumber {
  id: string;
  fieldNumber: number | null;
  refereeId?: string;
}

export interface IKnockoutGame {
  endTime: string;
  fieldNumber: number;
  id: string;
  poule: string;
  referee: string;
  startTime: string;
  team1: null | string;
  team2: null | string;
  team1Id: null | string;
  team2Id: null | string;
  team1Score: null | number;
  team2Score: null | number;
}

export interface IKnockout {
  name: string;
  type: string;
  games: IKnockoutGame[];
}

export interface IMatch {
  endTime: string;
  id: string;
  poule: string;
  referee: string;
  startTime: string;
  team1: string;
  team2: string;
  fieldNumber: number;
  team1Score: null | number;
  team2Score: null | number;
}

export enum Knockouts {
  LAST_32 = "LAST_32",
  LAST_16 = "LAST_16",
  QUARTER_FINAL = "QUARTER_FINAL",
  HALF_FINAL = "HALF_FINAL",
  FINAL = "FINAL",
}

export interface IMatchesPerTeams {
  date: Date;
  pouleGames: IMatch[];
  knockoutGames: IMatch[];
}
