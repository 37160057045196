import { Tab } from "@headlessui/react";
import { PoulesSelector } from "./PoulesSelector";

export function PouleTabs({
  setSelectedPouleIndex,
  selectedPouleIndex,
  data,
  isHerenToernooi,
}: {
  setSelectedPouleIndex: (index: number) => void;
  selectedPouleIndex: number;
  data: any;
  isHerenToernooi: boolean;
}) {
  return (
    <Tab.Group
      onChange={(d) => {
        if (d === selectedPouleIndex) {
          return;
        } else {
          console.log(selectedPouleIndex);
          setSelectedPouleIndex(d);
        }
      }}
      selectedIndex={selectedPouleIndex}
      defaultIndex={selectedPouleIndex}
    >
      <Tab.List
        style={{
          overflowY: "scroll",
          display: "flex",
          overflowX: "scroll",
          flexWrap: "nowrap",
          width: "100%",
          scrollbarColor: "#000",
          scrollbarWidth: "none",
        }}
      >
        <PoulesSelector
          data={data}
          isHerenToernooi={isHerenToernooi}
          selectedPouleIndex={selectedPouleIndex}
        />
      </Tab.List>
    </Tab.Group>
  );
}
