import { IKnockoutGame } from "../../data/api/types";
import { formatDate, getTime } from "../utils/formatDate";

function isEmpty(value: any) {
  return value === undefined || value === null || value === "";
}

function groupByStartTime(data: IKnockoutGame[]) {
  data.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

  const grouped = data.reduce((acc, item) => {
    const startTime = item.startTime;
    if (!acc[startTime]) {
      acc[startTime] = [];
    }
    acc[startTime].push(item);
    return acc;
  }, {} as { [key: string]: IKnockoutGame[] });

  return Object.values(grouped);
}

export function KnockoutsTable({ data }: { data: IKnockoutGame[] }) {
  const groupedData = groupByStartTime(data);

  return (
    <div
      style={{
        overflowX: "auto",
        WebkitScrollSnapType: "none",
        paddingBottom: 100,
      }}
    >
      {groupedData.map((group, index) => (
        <div key={index}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 20px",
              background: "#6A7178",
              color: "#fff",
            }}
          >
            <span>{formatDate(group[0].startTime)} </span>
            <span>
              {getTime(group[0].startTime)}
              {"-"}
              {getTime(group[0].endTime)}
            </span>
          </div>
          <table className="min-w-full divide-y divide-gray-200 mb-20">
            <tbody className="bg-white divide-y">
              {group.map((game: IKnockoutGame) => (
                <tr
                  key={game.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  <td
                    style={{
                      width: "33.33%",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "right",
                      textAlign: "right",
                    }}
                  >
                    {game.team1 ? game.team1 : "-"}
                  </td>
                  <td
                    style={{
                      width: "33.33%",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!isEmpty(game.team1Score) && !isEmpty(game.team2Score) ? (
                      <div
                        style={{
                          display: "flex",
                          padding: "10px 15px",
                          background: "#3ECA7E",
                          borderRadius: 4,
                          color: "#fff",
                          width: "85px",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ marginRight: 10 }}>{game.team1Score}</span>
                        <span>|</span>
                        <span style={{ marginLeft: 10 }}>{game.team2Score}</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          background: "#DEE2E6",
                          borderRadius: 4,
                          color: "#272B30",
                          width: 85,
                          height: 45,
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #ADB5BD",
                        }}
                      >
                        Veld {game.fieldNumber + 1}
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      width: "33.33%",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    {game.team2 ? game.team2 : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
