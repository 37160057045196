export default function Title({ text }: { text: string }) {
  return (
    <h3 className="text-purple-primary text-transparent text-3xl  bg-clip-text bg-gradient-to-br from-cyan-500 to-blue-700">
      {text}
    </h3>
  );
}

export function TitleWithLineBreak({
  text1,
  text2,
}: {
  text1: string;
  text2: string;
}) {
  return (
    <h3 className="text-purple-primary text-transparent text-3xl  bg-clip-text bg-gradient-to-br from-cyan-500 to-blue-700">
      {text1} <br></br>
      {text2}
    </h3>
  );
}

//   class="font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600"
