import { IMatchesPerTeams } from "../../data/api/types";
import { getDay, sameDates } from "../utils/formatDate";

export function SelectDay({
  onChange,
  values,
  selectedValue,
}: {
  onChange: (value: Date) => void;
  values: IMatchesPerTeams[];
  selectedValue: Date | undefined;
}) {
  const baseStyling = "w-1/2 rounded-sm text-center p-3 uppercase";
  const selectedStyling = "bg-purple-primary text-white ";
  const notSelectedStyling = "bg-slate-100";

  if (values.length === 1) {
    return null;
  }

  return (
    <div className="flex w-full p-5 justify-between">
      {values.map((match) => {
        const isSelectedDay = sameDates({
          date1: new Date(selectedValue || new Date()),
          date2: new Date(match.date),
        });

        return (
          <div
            style={{ cursor: "pointer", borderRadius: "6px 6px 6px 6px" }}
            tabIndex={0}
            onClick={() => onChange(new Date(match.date))}
            className={` ${
              isSelectedDay ? selectedStyling : notSelectedStyling
            }  ${baseStyling}`}
          >
            <span className={"font-semibold"}>
              {getDay(match.date.toString())}
            </span>
          </div>
        );
      })}
    </div>
  );
}
