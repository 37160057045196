import { Tab } from "@headlessui/react";
import { IPool } from "../../data/api/types";
import { useTournamentOptions } from "../../store";

function checkIfTeamIsInPoule({
	teamId,
	poule,
}: {
	teamId: string | null;
	poule: IPool;
}) {
	return poule.teams.findIndex((team) => team.team.id === teamId) >= 0;
}

export function PoulesSelector({
	data,
	isHerenToernooi,
	selectedPouleIndex,
}: {
	data: any;
	isHerenToernooi: boolean;
	selectedPouleIndex: number;
}) {
	const teamId = useTournamentOptions((store) => store.teamId);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				overflowY: "scroll",
			}}
		>
			{data?.map((poule: IPool, key: number) => {
				const isSelected = selectedPouleIndex === key;
				return (
					<Tab
						key={key}
						style={{
							minWidth: 110,
							borderBottom: isSelected ? "2px solid #653EF2" : "1px solid #DEE2E6",
							outline: "none",
						}}
						className={`${
							checkIfTeamIsInPoule({
								teamId: teamId || null,
								poule: poule,
							}) && "bg-slate-100"
						} 
            py-2
            sm:w-[260px]
            `}
					>
						<p style={{ display: "block", fontWeight: isSelected ? 600 : 500 }}>
							POULE {poule.name}
						</p>
					</Tab>
				);
			})}
		</div>
	);
}
