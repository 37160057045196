import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const BottomNavBar = ({ screen }: { screen: any }) => {
  const navigation = useNavigate();
  const [activeTabs, setActiveTabs] = useState("standen");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/app/standen")) {
      setActiveTabs("standen");
    } else if (location.pathname.includes("/app/wedstrijden")) {
      setActiveTabs("wedstrijden");
    } else {
      setActiveTabs("overige");
    }
  }, [navigation]);

  const handleOnClick = (name: string) => {
    switch (name) {
      case "standen":
        navigation("/app/standen/");
        break;
      case "wedstrijden":
        navigation("/app/wedstrijden/");
        break;
      case "overige":
        navigation("/app/overige");
        break;
      default:
        navigation("/app/standen/");
        break;
    }
    setActiveTabs(name);
  };

  return (
    <div
      style={{
        position: "relative",
        widows: "100%",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
        {screen}
      </div>
      <div
        style={{
          position: "absolute",
          borderTop: "2px solid #ccc",
          width: "100%",
          cursor: "pointer",
        }}
        className="w-full  flex justify-center items-center fixed bottom-0 bg-white "
      >
        <div
          style={{
            width: "33%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0px",
          }}
          onClick={() => {
            handleOnClick("standen");
          }}
        >
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <svg
              width="95"
              height="24"
              viewBox="0 0 95 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_501_3447"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="95"
                height="24"
              >
                <rect x="0.625" width="93.75" height="24" fill="#C4C4C4" />
              </mask>
              <g mask="url(#mask0_501_3447)">
                <path
                  fill={activeTabs === "standen" ? "#653EF2" : "#000"}
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M39.3131 1.6665C38.1969 1.6665 37.292 2.5714 37.292 3.68764V8.47998H44.1055V1.6665H39.3131ZM44.8555 0.166504H39.3131C37.3685 0.166504 35.792 1.74297 35.792 3.68764V9.22998V20.3145C35.792 22.2591 37.3685 23.8356 39.3131 23.8356H44.8555H55.9399C57.8846 23.8356 59.4611 22.2591 59.4611 20.3145V9.22998V3.68764C59.4611 1.74297 57.8846 0.166504 55.9399 0.166504H44.8555ZM45.6055 1.6665V8.47998H57.9611V3.68764C57.9611 2.5714 57.0562 1.6665 55.9399 1.6665H45.6055ZM44.1055 9.97998H37.292V20.3145C37.292 21.4307 38.1969 22.3356 39.3131 22.3356H44.1055V9.97998ZM45.6055 22.3356V9.97998H57.9611V20.3145C57.9611 21.4307 57.0562 22.3356 55.9399 22.3356H45.6055Z"
                />
              </g>
            </svg>

            <div />
            <p
              style={{
                color: activeTabs === "standen" ? "#653EF2" : "#000",
                paddingTop: 3,
              }}
            >
              Standen
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            handleOnClick("wedstrijden");
          }}
          style={{
            width: "33%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <svg
              width="31"
              height="24"
              viewBox="0 0 31 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.71118 1.6665C3.3751 1.6665 2.29199 2.74961 2.29199 4.08569V6.50491H6.29577C6.70999 6.50491 7.04577 6.84069 7.04577 7.25491V16.7625C7.04577 17.1767 6.70999 17.5125 6.29577 17.5125H2.29199V19.9316C2.29199 21.2677 3.3751 22.3508 4.71118 22.3508H15.0532V17.4617C12.3685 17.0957 10.2998 14.7938 10.2998 12.0087C10.2998 9.22354 12.3685 6.92159 15.0532 6.55561V1.6665H4.71118ZM15.8032 0.166504H4.71118C2.54667 0.166504 0.791992 1.92118 0.791992 4.08569V7.25491V16.7625V19.9316C0.791992 22.0961 2.54667 23.8508 4.71118 23.8508H15.8032H26.8955C29.06 23.8508 30.8147 22.0961 30.8147 19.9316V4.08569C30.8147 1.92118 29.06 0.166504 26.8955 0.166504H15.8032ZM16.5532 1.6665V6.55551C19.2383 6.92117 21.3074 9.22328 21.3074 12.0087C21.3074 14.794 19.2383 17.0962 16.5532 17.4618V22.3508H26.8955C28.2316 22.3508 29.3147 21.2677 29.3147 19.9316V17.5124H25.3105C24.8963 17.5124 24.5605 17.1767 24.5605 16.7624V7.25488C24.5605 6.84067 24.8963 6.50488 25.3105 6.50488H29.3147V4.08569C29.3147 2.74961 28.2316 1.6665 26.8955 1.6665H16.5532ZM16.5532 15.9424C18.4062 15.5914 19.8074 13.9637 19.8074 12.0087C19.8074 10.0536 18.4062 8.42591 16.5532 8.07494V15.9424ZM15.0532 8.07508C13.2007 8.42633 11.7998 10.0539 11.7998 12.0087C11.7998 13.9634 13.2007 15.591 15.0532 15.9422V8.07508ZM2.29199 16.0125V8.00491H5.54577V16.0125H2.29199ZM29.3143 16.0124H26.0605V8.00488H29.3143V16.0124Z"
                fill={activeTabs === "wedstrijden" ? "#653EF2" : "#000"}
              />
            </svg>
            <div />
            <p
              style={{
                color: activeTabs === "wedstrijden" ? "#653EF2" : "#000",
                paddingTop: 3,
              }}
            >
              Wedstrijden
            </p>
          </div>
        </div>
        <div
          style={{
            width: "33%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0px",
          }}
          onClick={() => {
            handleOnClick("overige");
          }}
        >
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <svg
              width="32"
              height="24"
              viewBox="0 0 32 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.37533 1.6665C2.77702 1.6665 2.29199 2.15153 2.29199 2.74984C2.29199 3.34815 2.77702 3.83317 3.37533 3.83317C3.97363 3.83317 4.45866 3.34815 4.45866 2.74984C4.45866 2.15153 3.97363 1.6665 3.37533 1.6665ZM0.791992 2.74984C0.791992 1.3231 1.94859 0.166504 3.37533 0.166504C4.80206 0.166504 5.95866 1.3231 5.95866 2.74984C5.95866 4.17657 4.80206 5.33317 3.37533 5.33317C1.94859 5.33317 0.791992 4.17657 0.791992 2.74984ZM16.2083 1.6665C15.61 1.6665 15.125 2.15153 15.125 2.74984C15.125 3.34815 15.61 3.83317 16.2083 3.83317C16.8066 3.83317 17.2917 3.34815 17.2917 2.74984C17.2917 2.15153 16.8066 1.6665 16.2083 1.6665ZM13.625 2.74984C13.625 1.3231 14.7816 0.166504 16.2083 0.166504C17.6351 0.166504 18.7917 1.3231 18.7917 2.74984C18.7917 4.17657 17.6351 5.33317 16.2083 5.33317C14.7816 5.33317 13.625 4.17657 13.625 2.74984ZM27.9585 2.74984C27.9585 2.15153 28.4435 1.6665 29.0418 1.6665C29.6401 1.6665 30.1252 2.15153 30.1252 2.74984C30.1252 3.34815 29.6401 3.83317 29.0418 3.83317C28.4435 3.83317 27.9585 3.34815 27.9585 2.74984ZM29.0418 0.166504C27.6151 0.166504 26.4585 1.3231 26.4585 2.74984C26.4585 4.17657 27.6151 5.33317 29.0418 5.33317C30.4686 5.33317 31.6252 4.17657 31.6252 2.74984C31.6252 1.3231 30.4686 0.166504 29.0418 0.166504Z"
                fill={activeTabs === "overige" ? "#653EF2" : "#000"}
              />
            </svg>

            <div />
            <p
              style={{
                color: activeTabs === "overige" ? "#653EF2" : "#000",
                paddingTop: 3,
              }}
            >
              Overige
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
