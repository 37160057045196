import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants/api";
import { ITeam, TeamClass } from "../types";

const QUERY_KEY = "teams";

const sortBasedOnTeamNames = (teams: ITeam[]) => {
  return teams.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export function useTeamsQuery(
  tournamentId: string,
  options?: UseQueryOptions<Record<TeamClass, ITeam[]>, AxiosError>
) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data: men } = await client.get(
        ENDPOINTS.TEAM_MEN.replace("{tournamentId}", tournamentId)
      );
      const { data: women } = await client.get(
        ENDPOINTS.TEAM_WOMEN.replace("{tournamentId}", tournamentId)
      );

      return {
        [TeamClass.MEN]: sortBasedOnTeamNames(men),
        [TeamClass.WOMEN]: sortBasedOnTeamNames(women),
      };
    },
    // @ts-ignore
    options
  );
}
