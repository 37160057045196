import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageLayout } from "../../components/pageLayout";
import SelectFromList from "../../components/selectFromList";
import Title, { TitleWithLineBreak } from "../../components/title";
import { ITeam } from "../../data/api/types";
import { useTeamsQuery } from "../../data/api/useTeams/useTeams";
import { useTournamentOptions } from "../../store";

export function SelectTeam() {
  const changeTeamId = useTournamentOptions((state) => state.setTeamId);
  const selectedTeam = useTournamentOptions((state) => state.teamId);
  const tournamentId = useTournamentOptions((state) => state.tournamentId);
  const setTournamentId = useTournamentOptions(
    (state) => state.setTournamentId
  );

  const setIsHerenToernooi = useTournamentOptions(
    (state) => state.setIsHerenToernooi
  );
  const setTeamName = useTournamentOptions((state) => state.setTeamName);

  const { data: teams, isLoading } = useTeamsQuery(tournamentId || "");
  const [selectedGender, setSelectedGender] = useState("MEN");

  const navigate = useNavigate();

  const onSelectTeam = ({
    id,
    name,
    teamClass,
  }: {
    id: string;
    name: string;
    teamClass: string;
  }) => {
    console.log(id, name);
    if (teamClass === "MEN") {
      setIsHerenToernooi(true);
    } else {
      setIsHerenToernooi(false);
    }
    changeTeamId(id);
    setTeamName(name);
    navigate("/app/standen/");
  };

  useEffect(() => {
    if (teams) {
      if (teams.women.length && !teams.men.length) {
        setSelectedGender("WOMEN");
      }
    }
  }, [teams]);

  return (
    <>
      <div
        style={{
          height: "100%",
          overflow: "scroll",
        }}
      >
        <div style={{ position: "sticky", top: 0, zIndex: 30 }}>
          <div className="bg-neutral-100 flex  font-semibold items-start justify-between p-5 sm:items-center  small-shadow border-b-2">
            <TitleWithLineBreak
              text1={"Kies jouw team"}
              text2={"uit deze lijst"}
            />
          </div>
          <SelectMaleFemaleFilter
            males={teams?.men?.length || -1}
            females={teams?.women?.length || -1}
            value={selectedGender}
            onChange={(value) => {
              setSelectedGender(value);
            }}
          />
        </div>
        <div style={{ position: "sticky", top: 0 }}>
          {" "}
          <SelectFromList
            values={selectedGender === "MEN" ? teams?.men : teams?.women}
            onChange={onSelectTeam}
            selectedValue={selectedTeam || teams?.men[0]?.["id"] || ""}
          />
          <div
            style={{
              position: "sticky",
              bottom: 0,
              left: 0,
              width: "100%",
              background: "#fff",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                padding: "20px 20px",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setTournamentId(null);
              }}
            >
              <img
                style={{
                  transform: "rotate(180deg)",
                  width: 10,
                  marginRight: 10,
                }}
                src="./arrow.png"
              ></img>
              Terug naar alle toernooien
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SelectMaleFemaleFilter({
  males,
  females,
  value,
  onChange,
}: {
  males: number;
  females: number;
  value: string;
  onChange: (value: any) => void;
}) {
  const baseStyling = "w-1/2 rounded-sm text-center p-3 rounded-sm uppercase";
  const selectedStyling = "bg-purple-primary text-white  ";
  const notSelectedStyling = "bg-slate-100";

  if (males > 0 && females > 0) {
    return (
      <div className="bg-white flex w-full p-5 justify-between ">
        <div
          onClick={() => onChange("MEN")}
          className={`${
            value === "MEN" ? selectedStyling : notSelectedStyling
          }  ${baseStyling}`}
        >
          <span> Heren</span>
        </div>
        <div
          onClick={() => onChange("WOMEN")}
          className={`${
            value === "WOMEN" ? selectedStyling : notSelectedStyling
          }  ${baseStyling}`}
        >
          <span> Dames</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
