import { useTournamentOptions } from "../../store";
import { IPool } from "../../data/api/types";
import { usePoolsTable } from "./usePoolsTable";
import useWindowDimensions from "../utils/windowSizeHook";

export function PoolsTable({
	data,
	isDetailedMode,
}: {
	data: IPool;
	isDetailedMode: boolean;
}) {
	const { width } = useWindowDimensions();
	const { tableInstance } = usePoolsTable({
		data: data?.teams,
		isDetailedMode: isDetailedMode,
		showFullName: width > 500,
	});

	const teamId = useTournamentOptions((store) => store.teamId);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		tableInstance;

	return (
		<div
			style={{
				overflowX: "auto",
				WebkitScrollSnapType: "none",
				overflowY: "scroll",
				paddingBottom: 100,
			}}
		>
			<table className="min-w-full divide-y divide-gray-200" {...getTableProps()}>
				<thead className="bg-[#6A7178] sticky top-0">
					<tr>
						<th className=" py-2 text-white font-medium">#</th>
						{
							// Loop over the header rows
							headerGroups.map((headerGroup) =>
								// Loop over the headers in each row
								headerGroup.headers.map((column) => (
									// Apply the header cell props
									<th
										className="px-2  py-2 text-white font-medium text-left"
										{...column.getHeaderProps()}
									>
										{
											// Render the header
											column.render("Header")
										}
									</th>
								))
							)
						}
					</tr>
				</thead>
				{/* Apply the table body props */}
				<tbody className="bg-white divide-y" {...getTableBodyProps()}>
					{
						// Loop over the table rows
						rows.map((row, index) => {
							const isSelectedTeam = row.original.team.id === teamId;
							// Prepare the row for display
							prepareRow(row);
							return (
								// Apply the row props
								<tr
									style={{
										backgroundColor: isSelectedTeam ? "#3D68F3" : "#fff",
									}}
									{...row.getRowProps()}
								>
									<td
										style={{ color: isSelectedTeam ? "#fff" : "#000" }}
										className="px-2 py-4 whitespace-nowrap text-base text-app-dark-primary"
									>
										{index + 1}
									</td>
									{
										// Loop over the rows cells
										row.cells.map((cell) => {
											// Apply the cell props
											return (
												<td
													style={{ color: isSelectedTeam ? "#fff" : "#000" }}
													className={`px-2 py-4 whitespace-nowrap text-base text-app-dark-primary`}
													{...cell.getCellProps()}
												>
													{
														// Render the cell contents
														cell.render("Cell")
													}
												</td>
											);
										})
									}
								</tr>
							);
						})
					}
				</tbody>
			</table>
		</div>
	);
}
