import client from "../../client";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { ENDPOINTS } from "../../../constants/api";
import { IKnockout, TeamClass } from "../types";

const QUERY_KEY = "knockouts";

export function useKnockOutsQuery(
  tournamentId: string,
  options?: UseQueryOptions<Record<TeamClass, IKnockout[]>, AxiosError>
) {
  return useQuery(
    [QUERY_KEY, tournamentId],
    async () => {
      const { data: men } = await client.get(
        ENDPOINTS.KNOCKOUT_MEN.replace("{tournamentId}", tournamentId)
      );
      const { data: women } = await client.get(
        ENDPOINTS.KNOCKOUT_WOMEN.replace("{tournamentId}", tournamentId)
      );
      return { [TeamClass.MEN]: men, [TeamClass.WOMEN]: women };
    },
    // @ts-ignore
    options
  );
}
