import { IKnockoutGame, IMatch } from "../../data/api/types";
import { formatDate, getTime } from "../utils/formatDate";

function isEmpty(value: any) {
  return value === undefined || value === null || value === "";
}

export function WedstrijdTable({
  data,
  bottomMargin,
}: {
  data: IMatch[];
  bottomMargin?: boolean;
}) {
  if (!data.length) {
    return null;
  }

  return (
    <div
      style={{
        overflowX: "auto",
        WebkitScrollSnapType: "none",
        overflowY: "auto",
      }}
    >
      <table
        className={`min-w-full divide-y divide-gray-200 ${
          bottomMargin && "mb-20"
        }`}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px",
            background: "#6A7178",
            color: "#fff",
          }}
        >
          <span>{formatDate(data?.[0].startTime)} </span>
          <span>
            {getTime(data?.[0].startTime)}
            {"-"}
            {getTime(data?.[0].endTime)}
          </span>
        </div>
        <tbody className="bg-white divide-y ">
          {data.map((game: IMatch) => {
            return (
              <>
                <tr
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  <td
                    style={{
                      width: "33.33%",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "right",
                      textAlign: "right",
                      wordBreak: "break-word",
                    }}
                  >
                    {game.team1 ? game.team1 : "-"}
                  </td>
                  <td
                    style={{
                      width: "33.33%",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!isEmpty(game.team1Score) && !isEmpty(game.team2Score) ? (
                      <div
                        style={{
                          display: "flex",
                          padding: "10px 15px",
                          background: "#3ECA7E",
                          borderRadius: 4,
                          color: "#fff",
                          width: "85px",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ marginRight: 10 }}>
                          {game.team1Score}
                        </span>
                        <span>|</span>
                        <span style={{ marginLeft: 10 }}>
                          {game.team2Score}
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          background: "#DEE2E6",
                          borderRadius: 4,
                          color: "#272B30",
                          width: 85,
                          height: 45,
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #ADB5BD",
                        }}
                      >
                        Veld {game.fieldNumber + 1}
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      width: "33.33%",
                      margin: "auto",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "left",
                      textAlign: "left",
                      wordBreak: "break-word",
                    }}
                  >
                    {game.team2 ? game.team2 : "-"}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
