export function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.84467 0.21967C1.13756 -0.0732233 1.61244 -0.0732233 1.90533 0.21967L9.78033 8.09467C10.0732 8.38756 10.0732 8.86244 9.78033 9.15533L1.90533 17.0303C1.61244 17.3232 1.13756 17.3232 0.84467 17.0303C0.551777 16.7374 0.551777 16.2626 0.84467 15.9697L8.18934 8.625L0.84467 1.28033C0.551777 0.987437 0.551777 0.512563 0.84467 0.21967Z"
        fill="black"
      />
    </svg>
  );
}
