import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import { IPool, IPoolTeam } from "../../data/api/types";

function getFirstLetters(str: string) {
	const contains_multiple_words = checkIfMultipleWords(str);

	if (!contains_multiple_words && str.length > 1) {
		return str.charAt(0) + str.charAt(1);
	} else if (str.length === 0) {
		return str.charAt(0);
	}

	const firstLetters = str
		.split(" ")
		.map((word) => word[0])
		.join("");

	return firstLetters;
}

function checkIfMultipleWords(string: string) {
	// check if string contains multiple words
	const regex = /\s/;
	const result = string.match(regex);
	if (result) {
		return true;
	}
	return false;
}

export function usePoolsTable({
	data,
	isDetailedMode,
	showFullName,
}: {
	data: IPoolTeam[];
	isDetailedMode: boolean;
	showFullName: boolean;
}) {
	const columns = useMemo(
		() => [
			{
				Header: "Teamnaam",
				accessor: (d: IPoolTeam) => d.team.name,
			},
			{
				Header: "DS",
				accessor: (d: IPoolTeam) => d.goalCount,
				sortType: "basic",
				isSorted: true,
				id: "DS",
			},
			{
				Header: "P",
				accessor: (d: IPoolTeam) => d.points,
				id: "P",
			},
		],
		[isDetailedMode]
	);

	const detailedColumns = useMemo(
		() => [
			{
				Header: "Teamnaam",
				accessor: (d: IPoolTeam) =>
					isDetailedMode && !showFullName ? getFirstLetters(d.team.name) : d.team.name,
			},
			{
				Header: "GW",
				accessor: (d: IPoolTeam) => d.matchesPlayed,
			},

			{
				Header: "W",
				accessor: (d: IPoolTeam) => d.matchesWon,
			},

			{
				Header: "G",
				accessor: (d: IPoolTeam) => d.matchesTied,
			},
			{
				Header: "V",
				accessor: (d: IPoolTeam) => d.matchesLost,
			},
			{
				Header: "DS",
				accessor: (d: IPoolTeam) => d.goalCount,
				sortType: "basic",
				isSorted: true,
				id: "DS",
			},
			{
				Header: "P",
				accessor: (d: IPoolTeam) => d.points,
				isSorted: true,
				sortType: "basic",
				id: "P",
			},
		],
		[isDetailedMode]
	);

	// @ts-ignore
	const tableInstance = useTable(
		{
			// @ts-ignore
			columns: isDetailedMode ? detailedColumns : columns,
			data: data,
			initialState: {
				// @ts-ignore
				sortBy: [
					{
						id: "P",
						desc: true,
					},
					{
						id: "DS",
						desc: true,
					},
				],
			},
			autoResortBy: false,
			maxMultiSortColCount: 20,
		},
		useSortBy
	);

	return { tableInstance };
}
